import {Select} from "antd";

export default function CustomSelect(props: any) {
    return (
        <Select
            {...props}
            {...props.searchable ? ({
                showSearch: true,
                optionFilterProp: "children",
                filterOption: (input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()),
                filterSort: (optionA, optionB) =>
                    (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare((optionB!.children as unknown as string).toLowerCase())
            }) : ({})}
        />
    )
}