
import React from "react";
import {Layout, FloatButton, Modal} from "antd";
import {BookOutlined, BugOutlined, InfoCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {releaseNotes} from "../release-notes";

export default function Footer(props: any) {

    const bugMailSubject = "[BugReport]: Was ist das Problem?"
    const bugMailBody = "Hallo ITP-Support,\n" +
        "bei der Nutzung von `KAIROS Access` habe ich das folgende Problem:\n" +
        "\n" +
        "Was war mein Ziel?\n" +
        "Hier hilft uns eine möglichst genaue Beschreibung der geplanten Tätigkeit, um diese nachstellen zu können.\n" +
        "\n" +
        "Was ist mein erwartetes Ergebnis?\n" +
        "Was sollte passieren? Diese Information benötigen wir, um evtl. unerwartete Workflows oder neue Anforderungen zu erkennen.\n" +
        "\n" +
        "Was war das reale Ergebnis?\n" +
        "Hier ist eine möglichst präzise Beschreibung des Fehlverhaltens hilfreich. Gerne können auch Screenshots beigefügt werden.\n"

    const showReleaseInfos = () => {
        Modal.info(
            {
                title: "Release Informationen",
                content: <div style={{height: '25vh', width: '100%', overflowY: 'scroll'}}>{releaseNotes}</div>,
                width: '50vw',
                maskClosable: true,
                okText: 'Schließen',
            }
        )
    }

    const showDokuInfos = () => {
        Modal.info(
            {
                title: "",
                content: <div style={{height: '25vh', width: '100%', overflowY: 'scroll'}}>
                    <h1>Handbuch</h1>
                    <p>Das Handbuch ist unter <a
                        href={"http://wiki.itp-consult.de/bin/view/Kairos/"}
                        target={"_blank"}
                    >http://wiki.itp-consult.de/bin/view/Kairos/</a> verfügbar.</p>
                    <p>Username: infrazeitz<br/>
                    Passwort: infrazeitz!</p>
                </div>,
                width: '50vw',
                maskClosable: true,
                okText: 'Schließen',
            }
        )
    }

    const help_float = (
        <FloatButton.Group
            trigger='hover'
            style={{bottom: 18, right: 24}}
            icon={<QuestionCircleOutlined/>}
        >
            <FloatButton
                tooltip={"Handbuch"}
                icon={<BookOutlined />}
                onClick={() => showDokuInfos()}
            />
            <FloatButton
                tooltip={"Release Informationen"}
                icon={<InfoCircleOutlined />}
                onClick={() => showReleaseInfos()}
            />
            <FloatButton
                tooltip={"Einen Fehler melden"}
                icon={<BugOutlined />}
                href={
                    'mailto:support@itp-consult.de?subject='
                    + bugMailSubject
                    + '&body='
                    + bugMailBody.replaceAll("\n", "%0D%0A")
                }
            />
        </FloatButton.Group>
    )

    return (
        <Layout.Footer style={{padding: 24, color:'gray'}}>
            © 2021 - {dayjs().year()} ITP-Consult Gesellschaft für IT-Beratung mbH
            {help_float}
        </Layout.Footer>
    );
}