export const releaseVersion = '1.0'
export const releaseNotes = (
    <>
        <h1>v1.0</h1>
        <p>Release: 06.12.2024</p>

        <p>Diese Version von KAIROS unterstützt den Import von Daten aus der InfluxDB in Excel.<br/>
            Hierzu können der gewünschte Zeitraum und die gewünschte Abtastrate, sowie die gewünschten Sensoren
            ausgewählt werden.</p>

        <p>Für den Fall das die Software aktualisiert werden muss, wird hier ein Verlauf der Anpassungen zu finden sein.</p>
    </>
)
