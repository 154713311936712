import {Layout} from "antd";
import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";

export default function AppLayout({children}: { children: JSX.Element }) {
    return (
        <Layout style={{height: '100vh'}}>
            <Header/>
            <Layout>
                <div className='scrollable'>
                    <Layout.Content>
                        {children}
                    </Layout.Content>
                    <Footer/>
                </div>
            </Layout>
        </Layout>
    )
}

