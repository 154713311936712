import React from 'react';
import {ConfigProvider} from "antd";
import deDE from 'antd/lib/locale/de_DE';


import './style/app.css'
import AppLayout from "./layout";
import UrlConfigView from "./views/urlConfigView";

export default function App(props: any) {
    return (
        <React.StrictMode>
            <ConfigProvider
                locale={deDE}
                theme={{
                    token: {
                        colorPrimary: "#408CBF",
                        fontSize: 20,
                    }
                }}
            >
                <AppLayout>
                    <UrlConfigView/>
                </AppLayout>
            </ConfigProvider>
        </React.StrictMode>
    )
}



